<!-- 登录页 -->
<template>
  <div class="login">
    <header>
      <div>
        <p style="font-size:18px">黄埔人才网 | 企业端</p>
      </div>
      <div>
        <Button type="error" @click="$router.push({ name: 'Register' })">我要招聘</Button>
        <!-- <span style="margin-left:50px;color:#333333;cursor:pointer;">手机端</span> -->

        <Poptip style="margin-left:50px;color:#333333;cursor:pointer;" trigger="hover">
          <p>手机端</p>
          <div slot="content" style="display:flex;align-items:center;padding:20px">
            <div style="margin-right:15px">
              <img src="@/assets/images/login/login_t_l.png" alt="">
              <p style="text-align:center">小程序</p>
            </div>
            <div style="margin-left:15px">
              <img src="@/assets/images/login/login_t_r.png" alt="">
              <p style="text-align:center">公众号</p>
            </div>

          </div>
        </Poptip>

      </div>

    </header>
    <section>
      <div class="left">
        <img src="@/assets/images/login_c.png" alt="">
      </div>

      <div class="right">
        <Row>
          <Col span="8">
          <div class="label">
            <div>
              <img src="@/assets/images/login_b_2.png" alt="">
            </div>
            <div>
              <p style="color:#666">人才搜索</p>
              <p style="color:#999">大量人才等你招</p>
            </div>
          </div>
          <div class="label">
            <div>
              <img src="@/assets/images/login_b_3.png" alt="">
            </div>
            <div>
              <p style="color:#666">招聘无忧</p>
              <p style="color:#999">轻轻松松来招人</p>
            </div>
          </div>
          <div>

          </div>
          </Col>
          <Col span="16">
          <div class="top">
            <p style="font-size:28px;font-weight:Bold">登录企业账户</p>
            <div style="margin-top:15px">
              <p>没有账户？</p>
              <p style="color:#FF8300;cursor:pointer;" @click="$router.push({ name: 'Register' })">免费注册</p>
              <div>
                <img src="@/assets/images/login_right.png" alt="">
              </div>
            </div>
          </div>
          <div class="content">
            <Tabs :animated="false" v-model="Tabs">
              <TabPane label="短信登录" name='1'>
                <Input v-model="mobile">
                <span slot="prepend">手机号</span>
                </Input>

                <Input v-model="authcode" style="margin:20px 0">
                <span slot="prepend">验证码</span>
                <Button slot="append" type="text" @click="get_authcode" :loading="loading"
                  :disabled='disabled'>{{ text }}</Button>
                </Input>

              </TabPane>
              <TabPane label="密码登录" name='2'>
                <Input v-model.trim="Account">
                <span slot="prepend">账&nbsp;&nbsp;&nbsp;号</span>
                </Input>

                <Input v-model="password" style="margin:20px 0" password type="password">
                <span slot="prepend">密&nbsp;&nbsp;&nbsp;码</span>
                </Input>

              </TabPane>
            </Tabs>
            <Checkbox v-model="single">我已阅读并同意</Checkbox>
            <span style="color:#009DFF;cursor:pointer;" @click="agreement">《用户协议》</span>
            <span>和</span>
            <span style="color:#009DFF;cursor:pointer;" @click="privacy">《隐私政策》</span>
          </div>
          <div class="bottom">
            <Button type="warning" size="large" long @click="login" :loading="loading_login">立即登录</Button>
            <p @click="$router.push({ name: 'forgetPassword' })">忘记密码?</p>
          </div>
          </Col>
        </Row>
      </div>
    </section>
    <article>
      <p>企业免费注册，更多会员权益等你解锁！</p>
      <Button size="large" @click="$router.push('Register')">前去注册</Button>
    </article>
    <footer>
      <p>广州首信人力资源有限公司版权所有</p>
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#515a6e">
        粤ICP备2022028470号-1
      </a>
    </footer>
  </div>

</template>

<script>
export default {
  data() {
    return {
      loading_login: false,
      loading: false,
      disabled: false,
      text: '获取验证码',
      Tabs: "2",
      single: false,
      //手机号
      mobile: "",
      // 验证码
      authcode: "",
      //账号
      Account: "",
      //密码
      password: ""

    }
  },
  methods: {
    //协议
    agreement() {
      this.$router.push({ name: "agreement" })
    },
    //隐私
    privacy() {
      this.$router.push({ name: "privacy" })
    },
    //获取验证码
    get_authcode() {
      //验证手机号格式
      if (/^1[3-9][0-9]{9}$/.test(this.mobile)) {
        //验证手机号是否已注册
        this.loading = true
        this.app('enterpriseOperators', 'checkRegisterMobile', { mobile: this.mobile }).then(res => {
          if (res.data.mobileRegister) {
            //已被注册可以发送验证码
            this.app('enterpriseOperators', 'sendRegisterCode', { mobile: this.mobile }).then(res => {
              this.loading = false
              this.disabled = true
              this.text = '60'
              let clock = setInterval(() => {
                if (this.text) {
                  this.text--
                } else {
                  this.text = '获取验证码'
                  clearInterval(clock)
                  this.disabled = false
                }
              }, 1000);
            })
          } else {
            this.loading = false
            this.$Modal.warning({
              title: "您还未注册，请先注册再登录！",
              onOk: () => {
                this.$router.push('Register')
              },
              closable: true
            })
          }
        })
      } else {
        this.$Modal.warning({
          title: "手机号码格式不正确"
        })
      }
    },
    //登录
    login() {
      //验证勾选协议
      if (this.single) {
        //验证码登录
        if (this.Tabs == 1) {
          //验证手机号及验证码格式
          if (/^1[3-9][0-9]{9}$/.test(this.mobile) && /^[0-9]{6}$/.test(this.authcode)) {
            //手机号及验证码都填写完毕
            this.loading_login = true
            this.app('enterpriseOperators', 'checkCode', {
              mobile: this.mobile,
              code: this.authcode
            }).then(res => {
              if (res.code == 200) {
                console.log(res);
                localStorage.setItem('firm_mobile', this.mobile)
                localStorage.setItem('firm_token', res.data.token)
                localStorage.setItem('firm_enterpriseId', res.data.enterpriseId)
                localStorage.setItem('firm_check', 1)

                this.$router.push('Home')
                this.loading_login = false
              } else {
                this.$Modal.warning({
                  title: res.message
                })
                this.loading_login = false
              }
            })
          } else {
            this.$Modal.warning({
              title: "手机号码或验证码格式不正确"
            })
          }

        } else {
          //账号密码登录
          if (this.Account && this.password) {
            this.loading_login = true
            this.app('enterpriseOperators', 'login', {
              mobile: this.Account,
              password: this.password
            }).then(res => {
              console.log(res);
              this.loading_login = false
              if (res.code == 200) {
                console.log(res);
                localStorage.setItem('firm_mobile', this.Account)
                localStorage.setItem('firm_token', res.data.token)
                localStorage.setItem('firm_enterpriseId', res.employee.enterpriseId)
                //记录勾选
                localStorage.setItem('firm_check', 1)
                //储存账号及密码
                localStorage.setItem('firm_account', this.Account)
                localStorage.setItem('firm_password', this.password)

                this.$router.push('Home')

              }
              // else {
              //   this.$Modal.warning({
              //     title: res.message
              //   })
              //   this.loading_login = false
              // }
            })
          } else {
            this.$Modal.warning({
              title: "账号或密码不能为空"
            })
          }
        }
      } else {
        this.$Modal.warning({
          title: "请阅读并同意《用户协议》和《隐私政策》"
        })
      }

    }
  },
  created() {
    if (localStorage.getItem('firm_account')) {
      this.Account = localStorage.getItem('firm_account')
      this.password = localStorage.getItem('firm_password')
    }
    if (localStorage.getItem('firm_check')) {
      this.single = true
    }


  },

}
</script>

<style lang="less" scoped>
@import "./login.less";
</style>